@charset "utf-8";

@import "variables";


html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,input,textarea,button,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;outline:0;font-size:100%;font-weight:inherit;font-style:normal;vertical-align:baseline;}
body{-webkit-text-size-adjust:100%;line-height:1;}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
ul,ol{list-style:none}
blockquote,q{quotes:none}
blockquote:before,blockquote:after,q:before,q:after{content:none}
a{margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent;}
ins{background-color:#ff9;color:#000;text-decoration:none}
mark{background-color:#ff9;color:#000;font-style:italic;font-weight:bold}
del{text-decoration:line-through}
abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help}
table{border-collapse:collapse;border-spacing:0}
input,select{vertical-align:middle}
a{color:inherit;text-decoration:none;outline:none;}
img{-ms-interpolation-mode: bicubic;}

::selection{background:$baseColor;color:#fff;}
::-moz-selection{background:$baseColor;color:#fff;}

*{
	box-sizing: border-box;
}

html{
	position: relative;
	overflow-x: hidden;
}
body{
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-size: $fontSize;
	font-family: $fontBase;
	line-height: $lineHeight;
	letter-spacing: .1em;
	color: #eee;
	background-color: rgb(36,38,46);
	overflow-x: hidden;
}

img,
svg{
	width: 100%;
	height: auto;
}

/*
.inner{
	position: relative;
	z-index: 1;
	width: 100%;
	max-width: 1200px;
	margin: auto;
	padding: 0 20px;
}
.inner.max1300{
	max-width: 1300px;
}
.inner.max1100{
	max-width: 1100px;
}
.inner.max1000{
	max-width: 1000px;
}
.inner.max800{
	max-width: 800px;
}
*/
.inner{
	position: relative;
	z-index: 1;
	width: 100%;
	padding: 0 30px;
}

.in{
	position: relative;
}

.pc{display: none !important;}
.smt{display: block !important;}

h1{
	display: none;
}

time{
	opacity: .5;
	font-size: 13px;
	i{
		margin-right: 5px;
	}
}

small{
	font-size: 12px;
	font-weight: normal;
	opacity: .5;
}

.permalink{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
}

.h-xl,
.h-lg,
.h-md,
.h-sm{
	color: #fff;
	font-weight: 400;
	line-height: 1.8;
	// letter-spacing: .2em;
	span{
		display: inline-block;
		position: relative;
		z-index: 1;
	}
	strong{
		font-size: 1.6em;
	}
	small{
		display: block;
	}
}
.h-xl{
	font-size: 30px;
}
.h-lg{
	font-size: 20px;
}
.h-md{
	font-size: 18px;
}
.h-sm{
	font-size: 16px;
}

.fill-grid > * {
	position: relative;
	z-index: 1;
}
.fill-grid:before{
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	// opacity: .6;
	background-image: url(../svg/common/bg_grid.svg);
	background-size: 50%;
}

header#g-head{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	overflow-y: auto;
	width: 100%;
	height: 79px;
	background-color: #0A0F14;
	transition: height .6s cubic-bezier(.86,0,.07,1);
}

header#g-head.on{
	height: 100%;
}

#h-logo{
	width: 160px;
	padding: 15px;
}

#wrap_g-nav{
	display: none;
	padding-bottom: 40px;
}

nav#g-nav{
	ul{
		li{
			border-top: 1px solid rgba(255,255,255,.05);
			a{
				position: relative;
				display: block;
				line-height: 1.6;
				padding: 15px 20px;
				strong{

				}
				small{
					display: block;
				}
				img{
					position: absolute;
					top: 50%;
					right: 20px;
					width: 15px;
					transform: translateY(-50%);
					opacity: .5;
				}
			}
			a:hover{
				background-color: rgba(0,0,0,.1);
			}
		}
		li:first-child{
			border: 0;
		}
	}
}

#wrap{
	position: relative;
	z-index: 1;
	width: 100%;
	margin: 79px 0 0;
	background: url(../svg/common/bg_grid.svg);
	background-size: 50%;
}

main{
	display: block;
}

.sec{
	position: relative;
	padding: 60px 0;
	.sec-foot{
		margin: 50px 0 0;
	}
}

footer#g-foot{
	position: relative;
	background: url(../img/common/footer/bg.gif) no-repeat center;
	background-size: cover;
	.fsec{
		padding: 60px 30px;
	}

	.fsec.fsec01{
		text-align: center;
		background-color: rgba(5,20,80,.4);
		.h-lg{
			letter-spacing: .2em;
		}
		.caption{
			width: 80%;
			max-width: 300px;
			margin: 10px auto 0;
			line-height: 0;
		}
	}

	.fsec.fsec02{
		padding-bottom: 0;
		background-color: rgba(0,0,0,.6);
		.company-info{
			h6{
				small{
					display: inline-block;
					margin: 0 0 0 1em;
				}
			}
			.inquiry{
				span{
					display: inline-block;
				}
			}
		}
		.exlinks{
			margin: 20px 0 0;
			table{
				width: 100%;
				line-height: 1.4;
				tbody{
					tr{
						th,
						td{
							padding: 7.5px 0;
							vertical-align: middle;
						}
						th{
							width: 60px;
							line-height: 0;
						}
						td{
							padding-left: 10px;
							word-break: break-all;
						}
					}
				}
			}
		}
	}
}
#copyright{
	text-align: center;
	margin: 30px 0 0;
	padding: 15px;
}

#btn_menu{
	cursor: pointer;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 9;
	width: 79px;
	height: 79px;
	background-color: #0A0F14;
	// border-left: 1px solid rgba(255,255,255,.1);
	> div,
	> div span {
		display: inline-block;
		transition: all .4s;
		box-sizing: border-box;
	}
	> div {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 25px;
		height: 20px;
		transform: translate(-50%, -50%);
		span {
			position: absolute;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: #fff;
		}
		span:nth-of-type(1) {
			top: 0;
		}
		span:nth-of-type(2) {
			top: calc(50% - 1px);
		}
		span:nth-of-type(3) {
			bottom: 0;
		}
		span:nth-of-type(1) {
			-webkit-animation: menu-bar01 .6s forwards;
			animation: menu-bar01 .6s forwards;
		}
		span:nth-of-type(2) {
			transition: all .6s;
			opacity: 1;
		}
		span:nth-of-type(3) {
			-webkit-animation: menu-bar02 .6s forwards;
			animation: menu-bar02 .6s forwards;
		}
	}
}
#btn_menu.active{
	> div{
		span:nth-of-type(1) {
			-webkit-animation: active-menu-bar01 .6s forwards;
			animation: active-menu-bar01 .6s forwards;
		}
		span:nth-of-type(2) {
			opacity: 0;
		}
		span:nth-of-type(3) {
			-webkit-animation: active-menu-bar03 .6s forwards;
			animation: active-menu-bar03 .6s forwards;
		}
	}
}

#overlay{
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 7;
	width: 100%;
	height: 100%;
	display: none;
	background-color: rgba(0,0,0,0.5);
}

#loading{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999;
	width: 100%;
	height: 100%;
	background-color: #0A0F14;
}
#loader{
	position: absolute;
	top: 50%;
	left: 50%;
	width: 80px;
	height: 80px;
	z-index: 9;
	transform: translate(-50%, -50%);
}

/* --------------------------- lower */
#lower-main-view{
	position: relative;
	width: 100%;
	height: 0;
	padding-top: 50%;
	.lower-title{
		position: absolute;
		top: 0;
		left: 0;
		width: 50%;
		height: 100%;
		opacity: 0;
		transform: translateX(100%);
		transition: opacity 1.5s cubic-bezier(0.190, 1.000, 0.220, 1.000),
					transform 1.5s cubic-bezier(0.190, 1.000, 0.220, 1.000);;
		.in{
			position: relative;
			width: 100%;
			height: 100%;
			background-color: #fff;
			h2{
				text-align: center;
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100%;
				height: auto;
				color: #000;
				transform: translate(-50%, -50%);
			}
		}
	}
}
#lower-main-view.on{
	.lower-title{
		opacity: 1;
		transform: translateX(0);
	}
}

#breadcrumb {
	display: none;
}

table.defor{
	display: block;
	width: 100%;
	border: 1px solid rgba(255,255,255,.1);
	border-bottom: 0;
	tbody{
		display: block;
		width: 100%;
		tr{
			display: block;
			width: 100%;
			th,
			td{
				display: block;
				width: 100%;
				border-bottom: 1px solid rgba(255,255,255,.1);
			}
			th{
				background-color: rgba(255,255,255,.05);
				padding: 10px 15px;
				font-weight: bold;
			}
			td{
				padding: 15px;
				ul{
					line-height: 1.8;
					li{
						padding: 5px 0;
						strong{
							font-size: 16px;
							font-weight: bold;
						}
					}
				}
				span{
					display: inline-block;
				}
				small{
					color: #999;
					font-size: 13px;
				}
			}
		}
	}
}

#product{
	padding: 0;
	ul{
		letter-spacing: -.4em;
		li{
			letter-spacing: normal;
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: 50%;
			// padding: 20px;
			position: relative;
			background-color: #0a0f14;
			transform: scale(1);
			transition: transform .2s;
			.li-in{
				letter-spacing: .1em;
				position: relative;
				width: 100%;
				height: 0;
				padding-top: 100%;
				.li-content{
					text-align: center;
					position: absolute;
					top: 50%;
					left: 50%;
					z-index: 2;
					width: 85%;
					height: auto;
					line-height: 1.6;
					padding: 20px;
					transform: translate(-50%, -50%);
					h2{
						strong{
							display: inline-block;
							vertical-align: middle;
							font-size: 16px;
						}
						img{
							width: 15px;
							display: inline-block;
							vertical-align: middle;
							margin: 0 5px;
						}
					}
				}
				.li-img{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					img{
						-webkit-filter: grayscale(100%);
						-moz-filter: grayscale(100%);
						-ms-filter: grayscale(100%);
						-o-filter: grayscale(100%);
						filter: grayscale(100%);
						transition: filter .2;
					}
				}
				a.permalink{
					z-index: 3;
				}
			}
		}
		li:not(.nolink){
			.li-in:before{
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				z-index: 1;
				width: 85%;
				height: 85%;
				background-color: rgba(0,0,0,.7);
				transform: translate(-50%, -50%);
				transition: all .4s;
			}
		}
		li:not(.nolink):hover{
			z-index: 1;
			transform: scale(1.05);
			.li-in{
				.li-img{
					img{
						-webkit-filter: grayscale(0);
						-moz-filter: grayscale(0);
						-ms-filter: grayscale(0);
						-o-filter: grayscale(0);
						filter: grayscale(0);
					}
				}
			}
			.li-in:before{
				width: 100%;
				height: 100%;
			}
		}

	}
}

#news-list{
	margin: 0 0 -30px;
	article{
		margin: 0 0 30px;
		.art-in{
			position: relative;
			time{
				position: absolute;
				top: 0;
				left: 0;
				transform: rotate(90deg) translateY(-100%);
				transform-origin: left top;
			}
			.art-dtl{
				margin: 0 0 0 2.5em;
				.art-img{
					span{
						overflow: hidden;
						position: relative;
						top: 0;
						display: block;
						width: 100%;
						height: 0;
						padding-top: 65%;
						background-repeat: no-repeat;
						background-position: center;
						background-size: cover;
						box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),
						0px 2px 2px 0px rgba(0,0,0,0.14),
						0px 1px 5px 0px rgba(0,0,0,0.12);
						transition: top .65s cubic-bezier(0.19, 1, 0.22, 1),
									box-shadow .65s cubic-bezier(0.19, 1, 0.22, 1);
					}
				}
				h3{
					line-height: 1.8;
					margin: 1em 0 0;
					overflow: hidden;
					max-height: 4em;
					min-height: 4em;
					/*
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					*/
					padding-right: .8em;
					position: relative;
				}
				h3:before,
				h3:after{
					background: rgb(36,38,46);
					position: absolute;
				}
				h3:before{
					content: "...";
					font-weight: bold;
					bottom: .9em;
					right: 0;
					font-size: 12px;
				}
				h3:after{
					content: "";
					height: 100%;
					width: 100%;
				}
			}
		}
		.art-in:hover{
			.art-dtl{
				.art-img{
					span{
						top: -10px;
						box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),
									0px 8px 10px 1px rgba(0,0,0,0.14),
									0px 3px 14px 2px rgba(0,0,0,0.12);
					}
				}
			}
		}
	}
}

.art-tag{
	margin: 5px 0 0;
	span{
		display: inline-block;
		vertical-align: top;
		padding: 0 10px;
		margin: 0 10px 10px 0;
		font-size: 13px;
		background-color: #507DAA;
	}
}

#single-body{
	margin: 50px 0 0;
	p{
		margin: 15px 0;
	}
	img{
		width: auto;
		max-width: 100%;
		height: auto;
		margin: 10px 0 0;
	}
}

.sns{
	margin: 20px 0 0 -10px;
	ul{
		line-height: 0;
		li{
			display: inline-block;
			vertical-align: middle;
			margin: 0 7.5px;
			a {
				img{
					opacity: .5;
					width: 30px;
					height: 30px;
				}
			}
			a:hover{
				img{
					opacity: 1;
				}
			}
		}
	}
}

.btns{
	a,
	button{
		cursor: pointer;
		text-align: center;
		display: inline-block;
		width: 50%;
		max-width: 240px;
		color: #fff;
		font-family: $fontBase;
		line-height: 1.6;
		padding: 15px;
		border: 1px solid rgba(255,255,255,.2);
		background-color: transparent;
		transition: border .2s;
	}
	a:hover,
	button:hover{
		border: 1px solid rgba(255,255,255,.4);
	}
	a.white,
	button.white{
		background-color: rgba(255,255,255,.1);
	}
	a.green,
	button.green{
		background-color: #00a2a2;
		border: 1px solid #00a2a2;
	}
	a.green:hover,
	button.green:hover{
		background-color: lighten(#00a2a2, 5%);
		border: 1px solid lighten(#00a2a2, 5%);
	}
}
.btns.col2{
	margin-left: -7.5px;
	margin-right: -7.5px;
	a,
	button{
		width: calc(50% - 15px);
		margin: 0 7.5px;
	}
}

.pager{
	padding: 50px 0 0;
	text-align: center;
	a,
	span{
		text-align: center;
		display: inline-block;
		line-height: 13px;
		padding: 14px 16px;
		margin: 5px;
		border: 1px solid rgba(255,255,255,.2);
		transition: border .2s;
	}
	a:hover{
		border: 1px solid rgba(255,255,255,.4);
	}
	span{
		color: #000;
		background-color: #fff;
	}

	div{
		display: inline-block;
	}
}

form{
	b{
		color: #ffdab9;
		font-family: $fontGothic;
		font-weight: normal;
	}
	p.err{
		color: #ffdab9;
		font-size: 13px;
		margin: 5px 0 0;
	}
	input[type="text"],
	select,
	textarea{
		display: inline-block;
		vertical-align: middle;
		height: calc(2.75rem + 2px);
		padding: 0.375rem 0.75rem;
		// font-size: 1rem;
		line-height: 1.5;
		color: #fff;
		font-family: $fontBase;
		background-color: rgba(255,255,255,.1);
		background-clip: padding-box;
		border: 1px solid rgba(255,255,255,.2);
		border-radius: 0.25rem;
		box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
	input[type="text"],
	textarea{
		width: 100%;
	}
	input[type="text"].type_s{
		width: 20%;
		max-width: 125px;
	}
	input[type="text"].type_m{
		width: 50%;
		max-width: 300px;
	}
	input[type=radio],
	input[type=image],
	input[type=checkbox]{
		border: none;
		width: auto;
		height: auto;
		vertical-align: middle;
		margin: -3px 5px 0 0;
	}
	textarea{
		height: auto;
	}
	select{
		option{
			color: #495057;
		}
	}
	input[type="text"]:focus,
	textarea:focus{
		color: #495057;
		background-color: #fff;
		border-color: #80bdff;
		outline: 0;
		box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
	}
	.labels{
		margin: 0 0 15px;
	}
	label{
		display: inline-block;
		margin: 0 10px 10px 0;
	}

	table{
		width: 100%;
		tr{
			th,
			td{
				display: block;
			}
			th{
				// white-space: nowrap;
				text-align: left;
				font-size: 15px;
				font-weight: bold;
				small{
					display: inline-block;
					font-size: $fontSize;
					font-weight: normal;
				}
			}
			th.v-top{
				vertical-align: top;
			}
			td{
				padding: 5px 0 20px;
				em{
					display: block;
					margin: 10px 0 0;
				}
			}
		}
	}

	table.conf{
		tr{
			td{
				span{
					display: block;
					// border: 1px solid #ccc;
					padding: 10px 15px;
					border-radius: 3px;
					// background-color: #fff;
					background-color: rgba(255,255,255,.1);
				}
				span.top10{
					margin-top: 10px;
				}
				span.txtarea{
					padding: 10px 15px;
				}
				p.kind{
					margin: 0 0 10px !important;
				}
			}
		}
	}
	.btns{
		margin-top: 6%;
	}
}
.sec.sec-conf,
.sec.sec-finish{
	.sec-head{
		margin: 0 0 50px;
		p{
			margin: 10px 0 0;
		}
	}
}

.sec.sec-finish{
	.sec-body{
		p.domain{
			font-size: 26px;
			margin: 20px 0 0;
		}
	}
}


@media screen and (min-width: 600px) {

	.h-xl{
		font-size: 40px;
	}
	.h-lg{
		font-size: 22px;
	}
	.h-md{
		font-size: 20px;
	}
	.h-sm{
		font-size: 17px;
	}

	.fill-grid:before{
		background-size: 33.333333%;
	}
	
	#wrap{
		background-size: 33.333333%;
	}

	.sec{
		
	}

/* --------------------------- lower */
	#lower-main-view{
		padding-top: 33.333333%;
		.lower-title{
			width: 33.333333%;
		}
	}

	#product{
		ul{
			li{
				width: 33.333333%;
			}
		}
	}

	#news-list{
		letter-spacing: -.4em;
		margin: 0 -1em -30px 0;
		article{
			letter-spacing: normal;
			display: inline-block;
			vertical-align: top;
			width: 50%;
			padding: 0 1em 0 0;
			.art-in{
				letter-spacing: .1em;
			}
		}
	}

	form{
		table{
			tr{
				th,
				td{
					display: table-cell;
					vertical-align: top;
				}
				th{
					// width: 240px;
					width: 200px;
					padding: 10px 20px 10px 0;
					span{
						display: block;
						padding: 7.5px 0;
					}
				}
				th.v-top{
					padding: 20px 20px 20px 0;
				}
				td{
					padding: 10px 0;
				}
			}
		}
		table.conf{
			tr{
				th{
					// width: 240px;
					width: 200px;
				}
			}
		}
	}

}


@media screen and (min-width: 800px) {
	
	.inner{
		padding: 0 60px;
	}

	.pc{display: block !important;}
	.smt{display: none !important;}
	
	.h-xl{
		font-size: 50px;
	}
	.h-lg{
		font-size: 26px;
	}
	.h-md{
		font-size: 22px;
	}
	.h-sm{
		font-size: 18px;
	}

	header#g-head{
		width: 30%;
		height: 100%;
	}

	#h-logo{
		margin: 40px auto;
		width: 100px;
		padding: 0;
	}

	#wrap_g-nav{
		display: block;
	}

	#wrap{
		width: 70%;
		margin: 0 0 0 30%;
	}

	.sec{
		
	}

	footer#g-foot{
		.fsec{
			padding: 60px;
		}
	}

	#btn_menu{
		display: none;
	}

/* --------------------------- lower */
	#breadcrumb {
		display: block;
		white-space: nowrap;
		padding: 15px 20px 0;
		ol {
			overflow: hidden;
			li {
				float: left;
				font-size: 13px;
				margin: 0px 10px 0px 0px;
				a {
					position: relative;
					color: #00A2A2;
					padding: 0px 12px 0px 0px;
				}
				a:before{
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					right: 0px;
					width: 4px;
					height: 4px;
					border-top: 1px solid rgba(255,255,255,.5);
					border-right: 1px solid rgba(255,255,255,.5);
					transform: translateY(-50%) rotate(45deg);
				}
				a:hover{
					text-decoration: underline;
				}
			}
			li.cate {
				position: relative;
				padding: 0px 12px 0px 0px;
			}
			li.cate:before{
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				right: 0px;
				width: 4px;
				height: 4px;
				border-top: 1px solid rgba(255,255,255,.5);
				border-right: 1px solid rgba(255,255,255,.5);
				transform: translateY(-50%) rotate(45deg);
			}
		}
	}

	table.defor{
		display: table;
		tbody{
			display: table-row-group;
			tr{
				display: table-row;
				width: auto;
				th,
				td{
					display: table-cell;
					vertical-align: top;
					width: auto;
					padding: 15px 20px;
				}
				th{
					width: 20%;
					border-right: 1px solid rgba(255,255,255,.1);
				}
			}
		}
	}

	.pager{
		margin: 0;
		text-align: left;
	}
}


@media screen and (min-width: 1000px) {
	
	.inner{
		padding: 0 70px;
	}

	.h-xl{
		font-size: 60px;
	}
	.h-lg{
		font-size: 30px;
	}
	.h-md{
		font-size: 24px;
	}
	.h-sm{
		font-size: 19px;
	}

	.fill-grid:before{
		background-size: 25%;
	}

	header#g-head{
		width: 25%;
	}

	#wrap{
		width: 75%;
		margin: 0 0 0 25%;
		background-size: 25%;
	}

	.sec{
		
	}

	footer#g-foot{
		.fsec{
			padding: 60px 70px;
		}
	}

/* --------------------------- lower */
	#lower-main-view{
		padding-top: 25%;
		.lower-title{
			width: 25%;
		}
	}

	#product{
		ul{
			li{
				width: 25%;
			}
		}
	}

}


@media screen and (min-width: 1200px) {
	
	.inner{
		padding: 0 80px;
	}

	.h-xl{
		font-size: 70px;
	}
	.h-lg{
		font-size: 32px;
	}
	.h-md{
		font-size: 26px;
	}
	.h-sm{
		font-size: 20px;
	}

	.fill-grid:before{
		background-size: 20%;
	}

	header#g-head{
		width: 19%;
	}

	#wrap{
		width: 81%;
		margin: 0 0 0 19%;
		background-size: 20%;
	}

	.sec{
		
	}

	footer#g-foot{
		.fsec{
			padding: 60px 80px;
		}
	}

/* --------------------------- lower */
	#lower-main-view{
		padding-top: 20%;
		.lower-title{
			width: 20%;
		}
	}

	#news-list{
		article{
			width: 33.333333%;
		}
	}

}


/* mCustomScrollbar */
.mCSB_scrollTools {
	// width: 2px;
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	width: 2px;
	background-color: rgba(255,255,255,.5);
}
.mCSB_inside > .mCSB_container {
	margin-right: 0;
}


/* keyframes */
@-webkit-keyframes menu-bar01 {
	0% { -webkit-transform: translateY(9px) rotate(45deg); }
	50% { -webkit-transform: translateY(9px) rotate(0); }
	100% { -webkit-transform: translateY(0) rotate(0); }
}
@keyframes menu-bar01 {
	0% { transform: translateY(9px) rotate(45deg); }
	50% { transform: translateY(9px) rotate(0); }
	100% { transform: translateY(0) rotate(0); }
}

@-webkit-keyframes menu-bar02 {
	0% { -webkit-transform: translateY(-9px) rotate(-45deg); }
	50% { -webkit-transform: translateY(-9px) rotate(0); }
	100% { -webkit-transform: translateY(0) rotate(0); }
}
@keyframes menu-bar02 {
	0% { transform: translateY(-9px) rotate(-45deg); }
	50% { transform: translateY(-9px) rotate(0); }
	100% { transform: translateY(0) rotate(0); }
}

@-webkit-keyframes active-menu-bar01 {
	0% { -webkit-transform: translateY(0) rotate(0); }
	50% { -webkit-transform: translateY(9px) rotate(0); }
	100% { -webkit-transform: translateY(9px) rotate(45deg); }
}
@keyframes active-menu-bar01 {
	0% { transform: translateY(0) rotate(0); }
	50% { transform: translateY(9px) rotate(0); }
	100% { transform: translateY(9px) rotate(45deg); }
}

@-webkit-keyframes active-menu-bar03 {
	0% { -webkit-transform: translateY(0) rotate(0); }
	50% { -webkit-transform: translateY(-9px) rotate(0); }
	100% { -webkit-transform: translateY(-9px) rotate(-45deg); }
}
@keyframes active-menu-bar03 {
	0% { transform: translateY(0) rotate(0); }
	50% { transform: translateY(-9px) rotate(0); }
	100% { transform: translateY(-9px) rotate(-45deg); }
}